import { RfqActionTypes as ActionTypes } from './constants';

export const createRfqRequest = (data: any) => ({
  type: ActionTypes.CREATE_RFQ_REQUEST,
  payload: {data},
});

export const resetRfqSuccessState = () => ({
  type: ActionTypes.RESET_RFQ_SUCCESS_STATE,
});

export const createRfqSuccess = (response: any) => ({
  type: ActionTypes.CREATE_RFQ_SUCCESS,
  payload: response,
});

export const createRfqFailure = (error: any) => ({
  type: ActionTypes.CREATE_RFQ_FAILURE,
  payload: error,
});

export const resetSingleRfq = () => ({
  type: ActionTypes.RESET_SINGLE_RFQ,
});

export const fetchRfqsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_RFQS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchRfqsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_RFQS_SUCCESS,
  payload: response,
});

export const fetchRfqsFailure = (error: any) => ({
  type: ActionTypes.FETCH_RFQS_FAILURE,
  payload: error,
});

export const fetchRfqByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_RFQ_BY_ID_REQUEST,
  payload: id,
});

export const fetchRfqByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_RFQ_BY_ID_SUCCESS,
  payload: response,
});

export const fetchRfqByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_RFQ_BY_ID_FAILURE,
  payload: error,
});

export const editRfqRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_RFQ_REQUEST,
  payload: { id, data },
});

export const editRfqSuccess = (response: any) => ({
  type: ActionTypes.EDIT_RFQ_SUCCESS,
  payload: response,
});

export const editRfqFailure = (error: any) => ({
  type: ActionTypes.EDIT_RFQ_FAILURE,
  payload: error,
});
