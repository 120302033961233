export enum ItemActionTypes {
    CREATE_ITEM_REQUEST = 'CREATE_ITEM_REQUEST',
    CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS',
    CREATE_ITEM_FAILURE = 'CREATE_ITEM_FAILURE',
    FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST',
    FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS',
    FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE',
    FETCH_ITEM_BY_ID_REQUEST = 'FETCH_ITEM_BY_ID_REQUEST',
    FETCH_ITEM_BY_ID_SUCCESS = 'FETCH_ITEM_BY_ID_SUCCESS',
    FETCH_ITEM_BY_ID_FAILURE = 'FETCH_ITEM_BY_ID_FAILURE',
    EDIT_ITEM_REQUEST = 'EDIT_ITEM_REQUEST',
    EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS',
    EDIT_ITEM_FAILURE = 'EDIT_ITEM_FAILURE',
    RESET_ITEM_SUCCESS_STATE = 'RESET_ITEM_SUCCESS_STATE',
    FETCH_SINGLE_ITEM = 'FETCH_SINGLE_ITEM',
    SET_SINGLE_ITEM = 'SET_SINGLE_ITEM',
    RESET_SINGLE_ITEM = 'RESET_SINGLE_ITEM'
}
