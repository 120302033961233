import { APICore } from "./apiCore";
const api = new APICore();

function createQuotation(data: any) {
    const baseUrl = `/quotation/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchQuotations(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/quotation/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchQuotationById(id: number) {
    const url = `/quotation/${id}`;
    return api.get(url, "");
}

function editQuotation(id: number, data: any) {
    const url = `/quotation/${id}`;
    return api.update(url, data);
}


async function createQuotationWithFiles(params: { quotationData: any, imageData?: any }) {
    try {
        const baseUrl = '/quotation';

        const createdQuotation = await api.create(baseUrl, { ...params.quotationData });

        const quotationResponse = createdQuotation.data;

        if (params?.imageData && params?.imageData.length > 0) {
            const moduleName = 'Products';
            const formData = new FormData();

            console.log("params?.imageData");
            console.log(params?.imageData);
        
            // Iterate over each entry in params.imageData
            for (let [key, value] of params?.imageData?.entries()) {
                if (key.startsWith('file')) {
                    // For file fields, append each file individually
                    formData.append('files', value);
                } else {
                    // For non-file fields, append them normally
                    formData.append(key, value);
                }
            }
        
            // Remove unnecessary fields
            const fieldsToDelete = ['needApproval', 'visibility[]', 'tagId', 'typeId', 'type', 'uploadedBy'];
            fieldsToDelete.forEach(field => formData.delete(field));
        
            formData.append('moduleName', moduleName);
        }

        const updatedBody = {
            id: quotationResponse?.data?.newQuotation?.id,
            body: {
                ...quotationResponse?.data?.newQuotation
            }
        };

        const baseQuotationUrl = `/quotation/${quotationResponse?.data?.newQuotation?.id}`;
        const updateQuotation = await api.update(baseQuotationUrl, { ...updatedBody.body });
        
        return updateQuotation;

    } catch (error) {
        console.error('Error creating quotation with files:', error);
        throw error;
    }
}

async function updateQuotationWithFiles(params: { id: number, quotationData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'Quotation');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "35");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.quotationData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/quotation/${params.id}`;
        return api.update(baseUrl, {
            ...params.quotationData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating quotation with files:', error);
        throw error;
    }
}




export { createQuotation, fetchQuotationById, fetchQuotations, editQuotation, createQuotationWithFiles, updateQuotationWithFiles };
