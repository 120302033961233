export enum ItemCategoryActionTypes {
    CREATE_ITEM_CATEGORY_REQUEST = 'CREATE_ITEM_CATEGORY_REQUEST',
    CREATE_ITEM_CATEGORY_SUCCESS = 'CREATE_ITEM_CATEGORY_SUCCESS',
    CREATE_ITEM_CATEGORY_FAILURE = 'CREATE_ITEM_CATEGORY_FAILURE',
    FETCH_ITEM_CATEGORIES_REQUEST = 'FETCH_ITEM_CATEGORIES_REQUEST',
    FETCH_ITEM_CATEGORIES_SUCCESS = 'FETCH_ITEM_CATEGORIES_SUCCESS',
    FETCH_ITEM_CATEGORIES_FAILURE = 'FETCH_ITEM_CATEGORIES_FAILURE',
    FETCH_ITEM_CATEGORY_BY_ID_REQUEST = 'FETCH_ITEM_CATEGORY_BY_ID_REQUEST',
    FETCH_ITEM_CATEGORY_BY_ID_SUCCESS = 'FETCH_ITEM_CATEGORY_BY_ID_SUCCESS',
    FETCH_ITEM_CATEGORY_BY_ID_FAILURE = 'FETCH_ITEM_CATEGORY_BY_ID_FAILURE',
    EDIT_ITEM_CATEGORY_REQUEST = 'EDIT_ITEM_CATEGORY_REQUEST',
    EDIT_ITEM_CATEGORY_SUCCESS = 'EDIT_ITEM_CATEGORY_SUCCESS',
    EDIT_ITEM_CATEGORY_FAILURE = 'EDIT_ITEM_CATEGORY_FAILURE',
    RESET_ITEM_CATEGORY_SUCCESS_STATE = 'RESET_ITEM_CATEGORY_SUCCESS_STATE',
    FETCH_SINGLE_ITEMCATEGORY = 'FETCH_SINGLE_ITEMCATEGORY',
    SET_SINGLE_ITEMCATEGORY = 'SET_SINGLE_ITEMCATEGORY',
    RESET_SINGLE_ITEM_CATEGORY = 'RESET_SINGLE_ITEM_CATEGORY'
  }
  