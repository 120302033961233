import { ActionTypes } from "./constant";

export type ProcessActionType = {
    type:
    | ActionTypes.CREATE_PROCESS_FAILURE
    | ActionTypes.CREATE_PROCESS_REQUEST
    | ActionTypes.CREATE_PROCESS_SUCCESS
    | ActionTypes.EDIT_PROCESS_FAILURE
    | ActionTypes.EDIT_PROCESS_REQUEST
    | ActionTypes.EDIT_PROCESS_SUCCESS
    | ActionTypes.FETCH_PROCESSES_FAILURE
    | ActionTypes.FETCH_PROCESSES_REQUEST
    | ActionTypes.FETCH_PROCESSES_SUCCESS
    | ActionTypes.FETCH_PROCESS_BY_ID_FAILURE
    | ActionTypes.FETCH_PROCESS_BY_ID_REQUEST
    | ActionTypes.FETCH_PROCESS_BY_ID_SUCCESS
    | ActionTypes.RESET_SUCCESS_STATE;
    
    payload: {} | string;
};

// Processes actions
export const createProcessRequest = (data: any) => ({
    type: ActionTypes.CREATE_PROCESS_REQUEST,
    payload: data,
});

export const resetSuccessState = () => ({
    type: ActionTypes.RESET_SUCCESS_STATE,
});



export const createProcessSuccess = (response: any) => ({
    type: ActionTypes.CREATE_PROCESS_SUCCESS,
    payload: response,
});

export const createProcessFailure = (error: any) => ({
    type: ActionTypes.CREATE_PROCESS_FAILURE,
    payload: error,
});

export const fetchProcessesRequest = (page: number, limit: number) => ({
    type: ActionTypes.FETCH_PROCESSES_REQUEST,
    payload: { page, limit },
});

export const fetchProcessesSuccess = (response: any) => ({
    type: ActionTypes.FETCH_PROCESSES_SUCCESS,
    payload: response,
});

export const fetchProcessesFailure = (error: any) => ({
    type: ActionTypes.FETCH_PROCESSES_FAILURE,
    payload: error,
});

export const fetchProcessByIdRequest = (id: number) => ({
    type: ActionTypes.FETCH_PROCESS_BY_ID_REQUEST,
    payload: id,
});

export const fetchProcessByIdSuccess = (response: any) => ({
    type: ActionTypes.FETCH_PROCESS_BY_ID_SUCCESS,
    payload: response,
});

export const fetchProcessByIdFailure = (error: any) => ({
    type: ActionTypes.FETCH_PROCESS_BY_ID_FAILURE,
    payload: error,
});

export const editProcessRequest = (id: number, data: any) => ({
    type: ActionTypes.EDIT_PROCESS_REQUEST,
    payload: { id, data },
});

export const editProcessSuccess = (response: any) => ({
    type: ActionTypes.EDIT_PROCESS_SUCCESS,
    payload: response,
});

export const editProcessFailure = (error: any) => ({
    type: ActionTypes.EDIT_PROCESS_FAILURE,
    payload: error,
});

export const processApiResponse = (actionType: string, data: any): ProcessActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_PROCESSES_SUCCESS:
        case ActionTypes.FETCH_PROCESS_BY_ID_SUCCESS:
        case ActionTypes.CREATE_PROCESS_SUCCESS:
        case ActionTypes.EDIT_PROCESS_SUCCESS:
            return {
                type: actionType,
                payload: data,
            };
        case ActionTypes.FETCH_PROCESSES_FAILURE:
        case ActionTypes.FETCH_PROCESS_BY_ID_FAILURE:
        case ActionTypes.CREATE_PROCESS_FAILURE:
        case ActionTypes.EDIT_PROCESS_FAILURE:
            return {
                type: actionType,
                payload: { error: "Error while setting data to store!" },
            };
        default:
            return {
                type: ActionTypes.FETCH_PROCESSES_FAILURE,
                payload: { actionType, error: "Unknown action type" },
            };
    }
};


export const processApiError = (actionType: string, error: any): ProcessActionType => {
    switch (actionType) {
        case ActionTypes.CREATE_PROCESS_REQUEST:
        case ActionTypes.EDIT_PROCESS_REQUEST:
        case ActionTypes.FETCH_PROCESSES_REQUEST:
        case ActionTypes.FETCH_PROCESS_BY_ID_REQUEST:
            return {
                type: ActionTypes.CREATE_PROCESS_FAILURE,
                payload: { error }
            };
        default:
            return {
                type: ActionTypes.CREATE_PROCESS_FAILURE,
                payload: { error: "Error while setting data to store!" }
            };
    }
};
