import { combineReducers } from "redux";
import Enquiry from "./enquiry/reducers";
import Task from "./task/reducers";
import Layout from "./layout/reducers";
import Auth from "./login/reducers";
import TransporterReducer from "./transporter/reducers";
import VendorReducer from "./vendor/reducers"
import TeamReducer from "./team/reducers";
import ReportReducer from "./report/reducers";
import EmployeeReducer from "./employee/reducers";
import Product from "./product/reducers";
import FormReducer from "./form/reducers";
import RoleReducer from "./role/reducers";
import ApprovalReducer from "./approval/reducers";
import ProjectTaskReducer from './project-management/reducers';
import CustomerReducer from "./customer/reducers"
import OrganizationReducer from "./organization/reducers"
import QueryReducer from "./query/reducers"
import FilesReducer from "./files/reducers";
import TagReducer from "./tag/reducers";
import ChatReducer from "./chat/reducers";
import LeadReducer from './lead/reducers'
import NotificationReducer from "./notification/reducers";
import JobdDefinationReducer from "./job-defination/reducers";
import TriggerReducer from "./trigger/reducers";
import MachineReducer from "./machine-management/reducers";
import WebsiteViewReducer from "./websitevisits/reducers";
import RawMaterialReducer from './raw-material/reducers'
import WarehouseReducer from './warehouse/reducers'
import SupplierReducer from './supplier/reducers'
import ContactReducer from "./contacts/reducers";
import LeadsReducer from "./lead-locator/reducers";
import ProcessReducer from "./processes/reducers";
import ZohoVendorReducer from "./zohoVendors/reducers";
import GateEntryReducer from "./gate-entry/reducers";
import POReducer from "./po/reducers";
import QuotationReducer from "./quotation/reducers";
import RfqReducer from "./rfq/reducers";
import IndentReducer from "./indents/reducers";
import ItemCategoryReducer from "./item-categories/reducers";
import ItemReducer from "./items/reducers";




export default combineReducers({
  Enquiry,
  Task,
  Layout,
  Auth,
  VendorReducer,
  TransporterReducer,
  TeamReducer,
  ReportReducer,
  EmployeeReducer,
  Product,
  FormReducer,
  RoleReducer,
  ApprovalReducer,
  ProjectTaskReducer,
  CustomerReducer,
  OrganizationReducer,
  QueryReducer,
  FilesReducer,
  TagReducer,
  ChatReducer,
  LeadReducer,
  NotificationReducer,
  JobdDefinationReducer,
  TriggerReducer,
  MachineReducer,
  WebsiteViewReducer,
  RawMaterialReducer,
  WarehouseReducer,
  SupplierReducer,
  contacts: ContactReducer,
  leads: LeadsReducer,
  processes: ProcessReducer,
  vendors: ZohoVendorReducer,
  gateEntrys: GateEntryReducer,
  po: POReducer,
  quotations: QuotationReducer,
  rfqs: RfqReducer,
  indents: IndentReducer,
  itemCategories: ItemCategoryReducer,
  items: ItemReducer,
});
