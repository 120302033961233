import { APICore } from "./apiCore";
const api = new APICore();

function createGateEntry(data: any) {
    const baseUrl = `/gate-entry/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchGateEntrys(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/gate-entry/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchGateEntryById(id: number) {
    const url = `/gate-entry/${id}`;
    return api.get(url, "");
}

function editGateEntry(id: number, data: any) {
    const url = `/gate-entry/${id}`;
    return api.update(url, data);
}


async function createGateEntryWithFiles(params: { gateEntryData: any, imageData?: any }) {
    try {
        const baseUrl = '/gate-entry';

        const createdGateEntry = await api.create(baseUrl, { ...params.gateEntryData });

        const gateEntryResponse = createdGateEntry.data;

        if (params?.imageData && params?.imageData.length > 0) {
            const moduleName = 'Products';
            const formData = new FormData();

            console.log("params?.imageData");
            console.log(params?.imageData);
        
            // Iterate over each entry in params.imageData
            for (let [key, value] of params?.imageData?.entries()) {
                if (key.startsWith('file')) {
                    // For file fields, append each file individually
                    formData.append('files', value);
                } else {
                    // For non-file fields, append them normally
                    formData.append(key, value);
                }
            }
        
            // Remove unnecessary fields
            const fieldsToDelete = ['needApproval', 'visibility[]', 'tagId', 'typeId', 'type', 'uploadedBy'];
            fieldsToDelete.forEach(field => formData.delete(field));
        
            formData.append('moduleName', moduleName);
        }

        const updatedBody = {
            id: gateEntryResponse?.data?.newGateEntry?.id,
            body: {
                ...gateEntryResponse?.data?.newGateEntry
            }
        };

        const baseGateEntryUrl = `/gate-entry/${gateEntryResponse?.data?.newGateEntry?.id}`;
        const updateGateEntry = await api.update(baseGateEntryUrl, { ...updatedBody.body });
        
        return updateGateEntry;

    } catch (error) {
        console.error('Error creating gateEntry with files:', error);
        throw error;
    }
}

async function updateGateEntryWithFiles(params: { id: number, gateEntryData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'GateEntry');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "35");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.gateEntryData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/gate-entry/${params.id}`;
        return api.update(baseUrl, {
            ...params.gateEntryData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating gateEntry with files:', error);
        throw error;
    }
}




export { createGateEntry, fetchGateEntryById, fetchGateEntrys, editGateEntry, createGateEntryWithFiles, updateGateEntryWithFiles };
