import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    count: 0,
    loading: true,
    vendors: [],
    error: {},
    success: false,
}

type ZohoVendorActionType = {
    type:
    AuthActionTypes.SET_VENDOR
    | AuthActionTypes.FETCH_VENDOR
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.SYNC_VENDORS
    | AuthActionTypes.SYNC_VENDORS_SUCCESS; 

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    }
}


const ZohoVendorReducer = (state: any = INIT_STATE, action: ZohoVendorActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_VENDOR:
            console.log("hello",action.payload)
            return { ...state, vendors: action.payload.data?.data?.response, loading: false, error: {}, success: true, count: action?.payload?.data?.data?.count};

        case AuthActionTypes.SET_ERROR:
            return { ...state, error: action.payload.error, loading: false };

        case AuthActionTypes.FETCH_VENDOR:
            return { ...state, loading: true };
        case AuthActionTypes.SYNC_VENDORS: 
            return { ...state, loading: true };
        case AuthActionTypes.SYNC_VENDORS_SUCCESS: 
            return { ...state, loading: false };
        default:
            return { ...state }
    }
}

export default ZohoVendorReducer;