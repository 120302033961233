import { APICore } from "./apiCore";
const api = new APICore();

function createPO(data: any) {
    const baseUrl = `/po/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchPOs(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/po/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchPOById(id: number) {
    const url = `/po/${id}`;
    return api.get(url, "");
}

function editPO(id: number, data: any) {
    const url = `/po/${id}`;
    return api.update(url, data);
}


async function createPOWithFiles(params: { poData: any, imageData?: any }) {
    try {
        const baseUrl = '/po';

        const createdPO = await api.create(baseUrl, { ...params.poData });

        const poResponse = createdPO.data;

        if (params?.imageData && params?.imageData.length > 0) {
            const moduleName = 'Products';
            const formData = new FormData();

            console.log("params?.imageData");
            console.log(params?.imageData);
        
            // Iterate over each entry in params.imageData
            for (let [key, value] of params?.imageData?.entries()) {
                if (key.startsWith('file')) {
                    // For file fields, append each file individually
                    formData.append('files', value);
                } else {
                    // For non-file fields, append them normally
                    formData.append(key, value);
                }
            }
        
            // Remove unnecessary fields
            const fieldsToDelete = ['needApproval', 'visibility[]', 'tagId', 'typeId', 'type', 'uploadedBy'];
            fieldsToDelete.forEach(field => formData.delete(field));
        
            formData.append('moduleName', moduleName);
        }

        const updatedBody = {
            id: poResponse?.data?.newPO?.id,
            body: {
                ...poResponse?.data?.newPO
            }
        };

        const basePOUrl = `/po/${poResponse?.data?.newPO?.id}`;
        const updatePO = await api.update(basePOUrl, { ...updatedBody.body });
        
        return updatePO;

    } catch (error) {
        console.error('Error creating po with files:', error);
        throw error;
    }
}

async function updatePOWithFiles(params: { id: number, poData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'PO');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "35");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.poData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/po/${params.id}`;
        return api.update(baseUrl, {
            ...params.poData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating po with files:', error);
        throw error;
    }
}




export { createPO, fetchPOById, fetchPOs, editPO, createPOWithFiles, updatePOWithFiles };
