import { APICore } from "./apiCore";
const api = new APICore();

function createProcess(data: any) {
    const baseUrl = `/processes/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchProcesses(page: any, limit: any) {
    const baseURL = `/processes/`
    const params = new URLSearchParams({
        page: page,
        limit: limit
    }).toString();
    const url = `${baseURL}?${params}`;

    return api.get(url, "");
}

function fetchProcessById(id: number) {
    const url = `/processes/${id}`;
    return api.get(url, "");
}

function editProcess(id: number, data: any) {
    const url = `/processes/${id}`;
    return api.update(url, data);
}

export { createProcess, fetchProcessById, fetchProcesses, editProcess};
