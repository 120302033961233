export enum POActionTypes {
    CREATE_PO_REQUEST = 'CREATE_PO_REQUEST',
    CREATE_PO_SUCCESS = 'CREATE_PO_SUCCESS',
    CREATE_PO_FAILURE = 'CREATE_PO_FAILURE',
    FETCH_POS_REQUEST = 'FETCH_POS_REQUEST',
    FETCH_POS_SUCCESS = 'FETCH_POS_SUCCESS',
    FETCH_POS_FAILURE = 'FETCH_POS_FAILURE',
    FETCH_PO_BY_ID_REQUEST = 'FETCH_PO_BY_ID_REQUEST',
    FETCH_PO_BY_ID_SUCCESS = 'FETCH_PO_BY_ID_SUCCESS',
    FETCH_PO_BY_ID_FAILURE = 'FETCH_PO_BY_ID_FAILURE',
    EDIT_PO_REQUEST = 'EDIT_PO_REQUEST',
    EDIT_PO_SUCCESS = 'EDIT_PO_SUCCESS',
    EDIT_PO_FAILURE = 'EDIT_PO_FAILURE',
    RESET_PO_SUCCESS_STATE = 'RESET_PO_SUCCESS_STATE',
    FETCH_SINGLE_PO = 'FETCH_SINGLE_PO',
    SET_SINGLE_PO = 'SET_SINGLE_PO',
    RESET_SINGLE_PO = 'RESET_SINGLE_PO'
}
