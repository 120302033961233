import { IndentActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  indents: [],
  singleIndent: {},
  error: {},
  success: false,
};

const IndentReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case IndentActionTypes.CREATE_INDENT_REQUEST:
    case IndentActionTypes.EDIT_INDENT_REQUEST:
    case IndentActionTypes.FETCH_INDENTS_REQUEST:
    case IndentActionTypes.FETCH_INDENT_BY_ID_REQUEST:
    case IndentActionTypes.RESET_INDENT_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case IndentActionTypes.SET_SINGLE_INDENT:
    return { ...state , singleIndent: action.payload.data, loading:false }  
    case IndentActionTypes.CREATE_INDENT_SUCCESS:
      return {
        ...state,
        indents: [...state.indents, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case IndentActionTypes.EDIT_INDENT_SUCCESS:
      const updatedIndents = state.indents.map((indent: any) =>
        indent.id === action.payload.data.id ? action.payload.data : indent
      );
      return {
        ...state,
        indents: updatedIndents,
        loading: false,
        error: {},
        success: true,
      };

    case IndentActionTypes.FETCH_INDENTS_SUCCESS:
      return {
        ...state,
        indents: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case IndentActionTypes.FETCH_INDENT_BY_ID_SUCCESS:
      return {
        ...state,
        singleIndent: action.payload.data,
        indents: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case IndentActionTypes.RESET_SINGLE_INDENT:
      return {
          ...state,
          singleIndent: null,
      };

    case IndentActionTypes.CREATE_INDENT_FAILURE:
    case IndentActionTypes.EDIT_INDENT_FAILURE:
    case IndentActionTypes.FETCH_INDENTS_FAILURE:
    case IndentActionTypes.FETCH_INDENT_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default IndentReducer;
