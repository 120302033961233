import { IndentActionTypes as ActionTypes } from './constants';

export const createIndentRequest = (data: any) => ({
  type: ActionTypes.CREATE_INDENT_REQUEST,
  payload: {data},
});

export const resetIndentSuccessState = () => ({
  type: ActionTypes.RESET_INDENT_SUCCESS_STATE,
});

export const createIndentSuccess = (response: any) => ({
  type: ActionTypes.CREATE_INDENT_SUCCESS,
  payload: response,
});

export const createIndentFailure = (error: any) => ({
  type: ActionTypes.CREATE_INDENT_FAILURE,
  payload: error,
});

export const resetSingleIndent = () => ({
  type: ActionTypes.RESET_SINGLE_INDENT,
});

export const fetchIndentsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_INDENTS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchIndentsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_INDENTS_SUCCESS,
  payload: response,
});

export const fetchIndentsFailure = (error: any) => ({
  type: ActionTypes.FETCH_INDENTS_FAILURE,
  payload: error,
});

export const fetchIndentByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_INDENT_BY_ID_REQUEST,
  payload: id,
});

export const fetchIndentByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_INDENT_BY_ID_SUCCESS,
  payload: response,
});

export const fetchIndentByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_INDENT_BY_ID_FAILURE,
  payload: error,
});

export const editIndentRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_INDENT_REQUEST,
  payload: { id, data },
});

export const editIndentSuccess = (response: any) => ({
  type: ActionTypes.EDIT_INDENT_SUCCESS,
  payload: response,
});

export const editIndentFailure = (error: any) => ({
  type: ActionTypes.EDIT_INDENT_FAILURE,
  payload: error,
});
