import { QuotationActionTypes as ActionTypes } from './constants';

export const createQuotationRequest = (data: any) => ({
  type: ActionTypes.CREATE_QUOTATION_REQUEST,
  payload: {data},
});

export const resetQuotationSuccessState = () => ({
  type: ActionTypes.RESET_QUOTATION_SUCCESS_STATE,
});

export const createQuotationSuccess = (response: any) => ({
  type: ActionTypes.CREATE_QUOTATION_SUCCESS,
  payload: response,
});

export const createQuotationFailure = (error: any) => ({
  type: ActionTypes.CREATE_QUOTATION_FAILURE,
  payload: error,
});

export const resetSingleQuotation = () => ({
  type: ActionTypes.RESET_SINGLE_QUOTATION,
});

export const fetchQuotationsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_QUOTATIONS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchQuotationsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_QUOTATIONS_SUCCESS,
  payload: response,
});

export const fetchQuotationsFailure = (error: any) => ({
  type: ActionTypes.FETCH_QUOTATIONS_FAILURE,
  payload: error,
});

export const fetchQuotationByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_QUOTATION_BY_ID_REQUEST,
  payload: id,
});

export const fetchQuotationByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_QUOTATION_BY_ID_SUCCESS,
  payload: response,
});

export const fetchQuotationByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_QUOTATION_BY_ID_FAILURE,
  payload: error,
});

export const editQuotationRequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_QUOTATION_REQUEST,
  payload: { id, data },
});

export const editQuotationSuccess = (response: any) => ({
  type: ActionTypes.EDIT_QUOTATION_SUCCESS,
  payload: response,
});

export const editQuotationFailure = (error: any) => ({
  type: ActionTypes.EDIT_QUOTATION_FAILURE,
  payload: error,
});
