import { APICore } from "./apiCore";
const api = new APICore();

function createItem(data: any) {
    const baseUrl = `/item/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchItems(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/item/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchItemById(id: number) {
    const url = `/item/${id}`;
    return api.get(url, "");
}

function editItem(id: number, data: any) {
    const url = `/item/${id}`;
    return api.update(url, data);
}


async function zohoEditItem(params: { id: number, itemData: any }) {
    try {
        const baseURL = `/item/zoho/${params.id}`;
        await api.update(baseURL, { ...params.itemData })

    } catch (error) {
        console.error('Error creating item with files:', error);
        throw error;
    }
}


async function createItemWithFiles(params: { itemData: any, imageData?: any, fileToUploadToZoho?: any }) {
    try {
        const baseUrl = '/item';
        const fileUrl = `file/upload`;
        const zohoFileUrl = 'auth/zoho/upload/file'

        const createdItem = await api.create(baseUrl, { ...params.itemData });

        const itemResponse = createdItem.data;

        const itemId = itemResponse?.data?.newItem?.id;


        const updatedCreatedBody = {
            id: itemResponse?.data?.newItem?.id,
            body: {
                ...itemResponse?.data?.newItem,
                zohoItemCode: itemResponse?.data?.newZohoItem?.data[0]?.details?.id
            }
        };
        const baseUpdateItemUrl = `/item/${itemResponse?.data?.newItem?.id}`;
        const updatedZohoCodeItem = await api.update(baseUpdateItemUrl, { ...updatedCreatedBody.body });


        let imageDataResponse = [];
        if (params.imageData) {
            const recordId = updatedZohoCodeItem?.data?.data?.zohoItemCode;
            const moduleName = 'Products';
            const formData = new FormData();
        
            // Iterate over each entry in params.imageData
            for (let [key, value] of params.imageData.entries()) {
                if (key.startsWith('file')) {
                    // For file fields, append each file individually
                    formData.append('files', value);
                } else {
                    // For non-file fields, append them normally
                    formData.append(key, value);
                }
            }
        
            // Remove unnecessary fields
            const fieldsToDelete = ['needApproval', 'visibility[]', 'tagId', 'typeId', 'type', 'uploadedBy'];
            fieldsToDelete.forEach(field => formData.delete(field));
        
            formData.append('moduleName', moduleName);
            formData.append('recordId', recordId);
        
            try {
                const zohoImageUploadResponse = await api.create(zohoFileUrl, formData);
                console.log("zoho",zohoImageUploadResponse)
                if (zohoImageUploadResponse ) {
                    params.imageData.append('uploadedBy', itemResponse?.data?.newItem?.createdBy);
                    params.imageData.append('type', 'Item');
                    params.imageData.append('typeId', itemId);
                    params.imageData.append('tagId', "35");
                    params.imageData.append('zohoImageId', zohoImageUploadResponse.data.data[0].details.id);
        
                    console.log("Sending imageData to second API:", params.imageData);
                    const imageFile = await api.create(fileUrl, params.imageData);
                    const imageFileResponse = imageFile.data;
                    imageDataResponse = (imageFileResponse?.data?.response?.fileIds || []).map((id: any) => Number(id));
        
                    console.log("Second API call response:", imageFileResponse);
                } else {
                    console.error("Unexpected response structure from Zoho:", zohoImageUploadResponse);
                }
            } catch (error) {
                console.error('Error uploading to Zoho:', error);
            }
        }
        
        
        

        const updatedBody = {
            id: itemResponse?.data?.newItem?.id,
            body: {
                ...itemResponse?.data?.newItem,
                image_id: imageDataResponse,
            }
        };

        const baseItemUrl = `/item/${itemResponse?.data?.newItem?.id}`;
        const updateItem = await api.update(baseItemUrl, { ...updatedBody.body });


        
        return updateItem;

    } catch (error) {
        console.error('Error creating item with files:', error);
        throw error;
    }
}

async function updateItemWithFiles(params: { id: number, itemData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'Item');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "35");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.itemData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/item/${params.id}`;
        return api.update(baseUrl, {
            ...params.itemData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating item with files:', error);
        throw error;
    }
}




export { createItem, fetchItemById, fetchItems, editItem, createItemWithFiles, updateItemWithFiles, zohoEditItem };
