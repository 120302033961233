import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import {
  DefaultLayout,
  VerticalLayout,
  HorizontalLayout,
  DetachedLayout,
  FullLayout,
} from "layouts";
// import Root from './Root';
import { LayoutTypes } from "appConstants";
import { useRedux } from "hooks";
import { APP_PRIVILEGES, UserType } from "redux/types/DataTypes";
import PermissionService from "services/PermissionService";
import PrivateRoute from "./PrivateRoute";
import ErrorPageNotFound from "pages/error/PageNotFound";
import ErrorPageNotFoundAlt from "pages/error/PageNotFoundAlt";



// lazy load all the views

// auth
const Login = React.lazy(() => import("pages/login/Login"));
const Logout = React.lazy(() => import("pages/account/Logout"));
const LockScreen = React.lazy(() => import("pages/account/LockScreen"));
const Dashboard = React.lazy(() => import("pages/dashboard/Ecommerce"));
const VendorSignup = React.lazy(() => import("pages/login/VendorTransporterSignup"));

//Enquiry Component
const Enquiry = React.lazy(() => import("pages/enquiry/Enquiry"));
const EnquiryAdd = React.lazy(() => import("pages/enquiryDetail/AddEnquiry"));
const EnquiryMap = React.lazy(() => import("pages/enquiry/EnquiryMap"));

//Approval Component
const Approval = React.lazy(() => import("pages/approval/Approval"))
const ApprovalAdd = React.lazy(() => import("pages/approval/AddApproval"))
const ApprovalUpdate = React.lazy(() => import("pages/approval/UpdateApproval"))
const ApprovalTypes = React.lazy(() => import("pages/approval-types/ApprovalType"))

//Vendor Component
const Vendor = React.lazy(() => import("pages/vendor/Vendor"));
const AddVendor = React.lazy(() => import("pages/vendor/AddVendor"))
const VendorMap = React.lazy(() => import("pages/vendor/AllVendorMap"))
const VendorDashboard = React.lazy(() => import('pages/dashboard/Ecommerce/VendorDashboard'))

const UpdateVendor = React.lazy(() => import("pages/vendor/UpdateVendor"))

//Chat Component
const Chat = React.lazy(() => import("pages/chat/Chat"));
const ChatList = React.lazy(() => import("pages/chat/ChatList"))
//const Chat = React.lazy(() => import("pages/ChatNew"));

//Transporter Component
const Transporter = React.lazy(() => import("pages/transporter/Transporter"));
const TransporterDetail = React.lazy(() => import("pages/transporter/TransportedDetail"))
const TransporterDashboard = React.lazy(() => import('../pages/dashboard/Ecommerce/TransporterDashBoard'))


// Supplier component
const Supplier = React.lazy(() => import("pages/supplier/Supplier"));
const SupplierDetails = React.lazy(() => import("pages/supplier/SupplierDetails"));
const SupplierDashboard = React.lazy(() => import('../pages/dashboard/Ecommerce/SupplierDashBoard'))


//employee Management
const Team = React.lazy(() => import("pages/empolyeeManagement/team/Team"))
const TeamAdd = React.lazy(() => import("pages/empolyeeManagement/team/AddTeam"))
const TeamUpdate = React.lazy(() => import("pages/empolyeeManagement/team/UpdateTeam"))

// heiracry team 
const HierarcyTeam = React.lazy(() => import("pages/empolyeeManagement/team/HierarcyTeam"))

const Employee = React.lazy(() => import("pages/empolyeeManagement/employee/Employee"))
const AddEmployee = React.lazy(() => import("pages/empolyeeManagement/employee/AddEmploy"))
const UpdateEmployee = React.lazy(() => import("pages/empolyeeManagement/employee/UpdateEmployee"))

//Product Component 
const Product = React.lazy(() => import("pages/catlogue/ProductList"));
const ProductDetail = React.lazy(() => import("pages/catlogue/ProductDetail"));
const SkuDetails = React.lazy(() => import("pages/catlogue/SkuTabs"));

//Form Component
const FormList = React.lazy(() => import("pages/custom-forms/FormList"));
const FormAdd = React.lazy(() => import("pages/custom-forms/AddForm"));

//Task Component
const Task = React.lazy(() => import("pages/task/Task"));
const TaskDetail = React.lazy(() => import("pages/taskDetail/TaskDetail"));
const TaskAdd = React.lazy(() => import("pages/taskDetail/AddTask"));

//Chat Component
// const Chat = React.lazy(() => import("pages/chat/Chat"));


//access-management
const RoleList = React.lazy(() => import("pages/access-management/RoleList"))
const RoleForm = React.lazy(() => import("pages/access-management/role-form"))

//project management
const Project = React.lazy(() => import("pages/project-management/project/project-detail"))
const Job = React.lazy(() => import("pages/project-management/jobs/job-detail/JobTabView"))
const GantChart = React.lazy(() => import("pages/project-management/jobs/job-gantt/GantChart"))
const JobDefinationTabView = React.lazy(() => import("pages/project-management/master-job/job-defination-tab-view/JobDefinationTabView"))

//locate leads
const LocateLeads = React.lazy(() => import("pages/locate-leads/leads-locate-list"))
const LocateLeadsDetail = React.lazy(() => import("pages/locate-leads/lead-locate-details"))
const DuplicateLeads = React.lazy(() => import("pages/locate-leads/duplicate-lead-locate-list"))


//organization
const Organization = React.lazy(() => import("pages/organization/Organization"))
const OrganizationDetail = React.lazy(() => import("pages/organization/OrganizationDetail"))
const OrganizationMap = React.lazy(() => import("pages/organization/OrganizationMap"))

//customer
const Customer = React.lazy(() => import("pages/customer/Customer"))
const CustomerDetails = React.lazy(() => import("pages/customer/CustomerDetails"))
const CustomerDashboard = React.lazy(() => import("pages/dashboard/Ecommerce/CustomerDashboard"))
const CustomerMap = React.lazy(() => import("pages/customer/CustomerMap"))

//Form
const Form = React.lazy(() => import("pages/enquiryDetail/Form/Form"))


//project task
const ProjectList = React.lazy(() => import("pages/project-management/project/project-list"))
const AssignedTask = React.lazy(() => import("pages/project-task/AssignedTasks"))
const JobAdd = React.lazy(() => import("pages/project-management/JobAdd"))
const ProjectMap = React.lazy(() => import("pages/project-management/project/project-map/ProjectMap"))
const ProjectGanttChart = React.lazy(() => import("pages/project-management/project/project-gantt/ProjectGanttView"))

const ContactList = React.lazy(() => import("pages/contacts/contacts-list"))
const ZohoVendorList = React.lazy(() => import("pages/zohoVendors/zoho-vendor-list"))

//raw material
const RawMaterialList = React.lazy(() => import("pages/raw-material/raw-material-list"))
const RawMaterialDetail = React.lazy(() => import("pages/raw-material/raw-material-form/RawMaterialForm"))
const RawMaterialChart = React.lazy(() => import("pages/raw-material/raw-material-chart/RawMaterialChart"))


//warehouse 
const WarehouseList = React.lazy(() => import("pages/warehouse/warehouse-list"))
const WarehouseDetail = React.lazy(() => import("pages/warehouse/warehouse-form/WarehouseForm"))
const WarehouseMap = React.lazy(() => import("pages/warehouse/inventory-detail/WarehouseMapView"))


//inventory
const InventoryList = React.lazy(() => import("pages/warehouse/inventory-list/inventoryList"))
const InventoryDetail = React.lazy(() => import("pages/warehouse/inventory-detail/InventoryDetails"))



//Query Component
const Query = React.lazy(() => import("pages/query/Query"));
const Uploader = React.lazy(() => import("components/CustomFileUploader"));


//Files
const Files = React.lazy(() => import("pages/files/Files"))

// vendor search
const VendorSearch = React.lazy(() => import("pages/VendorSearch/searchVendor"))

//tag
const Tag = React.lazy(() => import("pages/tag/Tag"))
const TagDetail = React.lazy(() => import("pages/tagDetail/TagDetail"))

//standard Form
const StandardForm = React.lazy(() => import("pages/enquiryDetail/Form/StandardForm"))

//Leads
const LeadDetail = React.lazy(() => import("pages/query/LeadDetails"))

//Processes
const Processes = React.lazy(() => import("pages/processes/processes-list"))
const ProcessesDetail = React.lazy(() => import("pages/processes/processes-details"))
const CreateEditProcesses = React.lazy(() => import("pages/processes/create-edit-processes"))


//Item categories
const ItemCategories = React.lazy(() => import("pages/item-categories/item-categories-list"))
const ItemCategoriesDetail = React.lazy(() => import("pages/item-categories/item-categories-details"))
const CreateEditItemCategories = React.lazy(() => import("pages/item-categories/create-edit-item-categories"))


//Items
const Item = React.lazy(() => import("pages/items/items-list"))
const ItemDetail = React.lazy(() => import("pages/items/items-details"))
const CreateEditItem = React.lazy(() => import("pages/items/create-edit-items"))

//Indents
const Indent = React.lazy(() => import("pages/indent/indents-list"))
const IndentDetail = React.lazy(() => import("pages/indent/indent-detail"))
const AddEditIndent = React.lazy(() => import("pages/indent/add-edit-indent"))

//Rfqs
const Rfq = React.lazy(() => import("pages/rfq/rfqs-list"))
const RfqDetail = React.lazy(() => import("pages/rfq/rfq-detail"))
const AddEditRfq = React.lazy(() => import("pages/rfq/add-edit-rfq"))

//Quotations
const Quotation = React.lazy(() => import("pages/quotation/quotations-list"))
const QuotationDetail = React.lazy(() => import("pages/quotation/quotation-detail"))
const AddEditQuotation = React.lazy(() => import("pages/quotation/add-edit-quotation"))

//PO
const PO = React.lazy(() => import("pages/po/po-list"))
const PODetail = React.lazy(() => import("pages/po/po-detail"))
const AddEditPO= React.lazy(() => import("pages/po/add-edit-po"))

//Gate Entrys
const GateEntry = React.lazy(() => import("pages/gate-entry/gate-entry-list"))
const GateEntryDetail = React.lazy(() => import("pages/gate-entry/gate-entry-detail"))
const AddEditGateEntry = React.lazy(() => import("pages/gate-entry/add-edit-gate-entry"))

//Triggers
const TriggerList = React.lazy(() => import("pages/trigger/trigger-list"))
const TriggerForm = React.lazy(() => import("pages/trigger/trigger-form/TriggerForm"))

//Transporter
// const TransportersignUp = React.lazy(() => import("pages/login/TransporterSignUp"))

//IOT
const Iot = React.lazy(() => import("pages/iot/platform-integration"));

//Reports
const ReportProject = React.lazy(() => import("pages/reports/project/ProjectReport"));

// Machines Component Import
const MachineDetail = React.lazy(() => import("pages/machine-management/machine-form"))
const MachineList = React.lazy(() => import("pages/machine-management/machine-list"))

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<(props: any) => JSX.Element>;
  userType?: UserType | UserType[] | '*';
  session?: any;
  requiredPrivileges?: string[];
};

const LoadComponent = ({ component: Component, userType, requiredPrivileges }: LoadComponentProps) => {
  const { appSelector } = useRedux();
  const { session } = appSelector((state) => ({
    session: state.Auth.session
  }));
  const permissionService = new PermissionService(session);
  const allowed = permissionService.validateUserType(userType) &&
    (requiredPrivileges ? permissionService.can(requiredPrivileges) : true);
  return allowed ?
    <Suspense fallback={loading()}>
      <Component />
    </Suspense> : <ErrorPageNotFoundAlt />
}

const AllRoutes = () => {
  const { appSelector } = useRedux();


  const { layout, session, } = appSelector((state) => ({
    layout: state.Layout,
    session: state.Auth.session,

  }));



  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;
    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();
  return useRoutes([
    {
      // public routes
      path: "/",
      element: session?.sessionId ? <Navigate to={"/auth"} /> : <DefaultLayout />,
      children: [
        { path: "*", element: <ErrorPageNotFound /> },
        { path: "/", element: <Navigate to={"/login"} /> },
        { path: "/login", element: <LoadComponent component={Login} userType={'*'} session={session} /> },
        { path: "/lock-screen", element: <LoadComponent component={LockScreen} /> },
        { path: "/account/logout", element: <LoadComponent component={Logout} /> },
        { path: "/vendorSignup", element: <LoadComponent component={VendorSignup} /> },

      ],
    },
    {
      // private routes
      path: "/auth",
      element: session?.sessionId ? (
        <PrivateRoute roles={"Admin"} component={Layout} />
      ) : (
        <Navigate to={"/"} />
      ),
      children: [
        { path: "*", element: <ErrorPageNotFoundAlt /> },

        { path: "/auth", element: <LoadComponent component={session.userType === UserType.EMPLOYEE ? Dashboard : session.userType === UserType.CUSTOMER ? CustomerDashboard : session.userType === UserType.VENDOR ? VendorDashboard : UserType.TRANSPORTER ? TransporterDashboard : SupplierDashboard} userType={[UserType.EMPLOYEE, UserType.CUSTOMER, UserType.VENDOR, UserType.TRANSPORTER, UserType.SUPPLIER]} session={session} /> },
        {
          path: "/auth/enquiry",
          element: <LoadComponent key={'enquiry'} component={Enquiry} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Enquiry_List.key]} />,
        },
        {
          path: "/auth/myenquiry",
          element: <LoadComponent key={'myenquiry'} component={Enquiry} userType={UserType.CUSTOMER} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Enquiry_List.key]} />,
        },
        // {
        //   path: "/auth/detail",
        //   element: <LoadComponent component={EnquiryDetail}  session={session} />,
        // },
        {
          path: "/auth/enquiryadd",
          element: <LoadComponent component={EnquiryAdd} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Update_Enquiry.key,]} />,
        },
        {
          path: "/auth/vendor",
          element: <LoadComponent component={Vendor} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Vendor_List.key]} />
        },
        {
          path: "/auth/addVendor",
          element: <LoadComponent component={AddVendor} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Vendor.key]} />
        },
        {
          path: "/auth/updateVendor",
          element: <LoadComponent component={UpdateVendor} userType={[UserType.EMPLOYEE, UserType.VENDOR]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Update_Vendor.key]} />
        },
        {
          path: "/auth/transporter",
          element: <LoadComponent component={Transporter} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Transporter_List.key]} />
        },
        {
          path: "/auth/transporterdetails",
          element: <LoadComponent component={TransporterDetail} userType={[UserType.TRANSPORTER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Transporter.key, APP_PRIVILEGES.May_Update_Transporter.key]} />
        },
        {
          path: "/auth/chat",
          element: <LoadComponent component={Chat} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} />
        },
        {
          path: "/auth/chatList",
          element: <LoadComponent component={ChatList} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} />
        },
        {
          path: "/auth/team",
          element: <LoadComponent component={Team} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Team_List.key]} />
        },
        {
          path: "/auth/teamadd",
          element: <LoadComponent component={TeamAdd} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Team.key]} />
        },
        {
          path: "/auth/employee",
          element: <LoadComponent component={Employee} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Employee_List.key]} />
        },
        {
          path: "/auth/addemployee",
          element: <LoadComponent component={AddEmployee} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Employee.key]} />
        },
        {
          path: "/auth/employeeupdate",
          element: <LoadComponent component={UpdateEmployee} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Update_Employee.key]} />
        },
        {
          path: "/auth/teamupdate",
          element: <LoadComponent component={TeamUpdate} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Update_Team.key]} />
        },
        {
          path: "/auth/hierarchy",
          element: <LoadComponent component={HierarcyTeam} session={session} />
        },
        {
          path: "/auth/approval",
          element: <LoadComponent component={Approval} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Approval_List.key]} />
        },
        {
          path: "/auth/approvalAdd",
          element: <LoadComponent component={ApprovalAdd} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Approval.key]} />
        },
        {
          path: "/auth/approvalUpdate",
          element: <LoadComponent component={ApprovalUpdate} userType={[UserType.EMPLOYEE, UserType.CUSTOMER, UserType.VENDOR, UserType.TRANSPORTER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Update_Approval.key]} />
        },
        {
          path: "/auth/approvalTypes",
          element: <LoadComponent component={ApprovalTypes} session={session} />
        },
        {
          path: "/auth/product",
          element: <LoadComponent component={Product} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Product_List.key]} />
        },
        {
          path: "/auth/product-detail",
          element: <LoadComponent component={ProductDetail} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Product.key, APP_PRIVILEGES.May_Update_Product.key]} />
        },
        {
          path: "/auth/form",
          element: <LoadComponent component={FormList} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Form_List.key]} />
        },
        {
          path: "/auth/add-form",
          element: <LoadComponent component={FormAdd} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Form.key, APP_PRIVILEGES.May_Update_Form.key]} />
        },
        {
          path: "/auth/task",
          element: <LoadComponent component={Task} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Task_List.key]} />
        },
        {
          path: "/auth/taskadd",
          element: <LoadComponent component={TaskAdd} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Task.key]} />,
        },
        {
          path: "/auth/taskupdate",
          element: <LoadComponent component={TaskDetail} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Update_Task.key]} />,
        },
        {
          path: "/auth/role",
          element: <LoadComponent component={RoleList} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Roles_List.key]} />
        },
        {
          path: "/auth/role/new",
          element: <LoadComponent component={RoleForm} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Roles.key]} />
        },
        {
          path: "/auth/role/:id",
          element: <LoadComponent component={RoleForm} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Update_Roles.key]} />
        },
        {
          path: "/auth/project",
          element: <LoadComponent component={Project} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Update_Project.key]} />
        },
        {
          path: "/auth/organization",
          element: <LoadComponent component={Organization} requiredPrivileges={[APP_PRIVILEGES.Organization_Listing.key]} />
        },
        {
          path: "/auth/orgdetail",
          element: <LoadComponent component={OrganizationDetail} requiredPrivileges={[APP_PRIVILEGES.Organization_Listing.key]} />
        },
        {
          path: "/auth/customer",
          element: <LoadComponent component={Customer} requiredPrivileges={[APP_PRIVILEGES.Customer_Listing.key]} />
        },
        {
          path: "/auth/customerdetails",
          element: <LoadComponent component={CustomerDetails} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Update_Customer.key, APP_PRIVILEGES.May_Add_Customer.key]} />
        },
        {
          path: "/auth/project/list",
          element: <LoadComponent component={ProjectList} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Project_List.key]} />
        },
        {
          path: "/auth/project/list",
          element: <LoadComponent component={JobAdd} />
        },
        {
          path: "/auth/project/job",
          element: <LoadComponent component={Job} userType={[UserType.VENDOR, UserType.EMPLOYEE, UserType.TRANSPORTER, UserType.SUPPLIER]} requiredPrivileges={[APP_PRIVILEGES.May_Update_Job.key]} />
        },
        {
          path: "/auth/locate-leads",
          element: <LoadComponent component={LocateLeads} />
        },
        {
          path: "/auth/locate-leads/:id",
          element: <LoadComponent component={LocateLeadsDetail} />
        },
        {
          path: "/auth/locate-leads/duplicate",
          element: <LoadComponent component={DuplicateLeads} />
        },
        {
          path: "/auth/job",
          element: <LoadComponent key={'myjob'} component={AssignedTask} userType={[UserType.VENDOR, UserType.EMPLOYEE, UserType.TRANSPORTER, UserType.SUPPLIER]} requiredPrivileges={[APP_PRIVILEGES.May_Access_Job_List.key]} />
        },
        {
          path: "/auth/assigned/job",
          element: <LoadComponent key={'vendorjob'} component={AssignedTask} userType={[UserType.VENDOR, UserType.EMPLOYEE]} requiredPrivileges={[APP_PRIVILEGES.May_Access_Job_List.key]} />
        },
        {
          path: "/auth/leads",
          element: <LoadComponent component={Query} requiredPrivileges={[APP_PRIVILEGES.May_Access_Query.key]} />
        },
        {
          path: "/auth/cform",
          element: <LoadComponent component={Form} userType={[UserType.EMPLOYEE, UserType.CUSTOMER, UserType.VENDOR, UserType.TRANSPORTER]} />
        }, {
          path: "/auth/files",
          element: <LoadComponent component={Files} />
        }, {
          path: "/auth/uploader",
          element: <LoadComponent component={Uploader} />
        },
        {
          path: "/auth/vendors/search",
          element: <LoadComponent component={VendorSearch} requiredPrivileges={[APP_PRIVILEGES.May_Access_Job_List.key]} />
        },
        {
          path: "/auth/tag",
          element: <LoadComponent component={Tag} requiredPrivileges={[APP_PRIVILEGES.May_Access_Tag_List.key]} />
        },
        {
          path: "/auth/tagDetail",
          element: <LoadComponent component={TagDetail} requiredPrivileges={[APP_PRIVILEGES.May_Add_Tag.key, APP_PRIVILEGES.May_Update_Tag.key]} />
        },
        {
          path: "/auth/project/chart",
          element: <LoadComponent component={GantChart} requiredPrivileges={[APP_PRIVILEGES.May_Access_Project_List.key]} />
        },
        {
          path: "/auth/project/gantt-view",
          element: <LoadComponent component={ProjectGanttChart} requiredPrivileges={[APP_PRIVILEGES.May_Access_Project_List.key]} />
        },
        {
          path: "/auth/sforms",
          element: <LoadComponent component={StandardForm} userType={[UserType.EMPLOYEE, UserType.CUSTOMER, UserType.VENDOR, UserType.TRANSPORTER]} />
        },
        {
          path: "/auth/customer/dashboard",
          element: <LoadComponent component={CustomerDashboard} userType={UserType.CUSTOMER} />
        },
        {
          path: "/auth/vendorLocation",
          element: <LoadComponent component={VendorMap} requiredPrivileges={[APP_PRIVILEGES.May_Access_Vendor_List.key]} />
        },
        {
          path: "/auth/enquiryLocation",
          element: <LoadComponent component={EnquiryMap} requiredPrivileges={[APP_PRIVILEGES.May_Access_Enquiry_List.key]} />
        },
        {
          path: "/auth/customerLocation",
          element: <LoadComponent component={CustomerMap} requiredPrivileges={[APP_PRIVILEGES.Customer_Listing.key]} />
        },
        {
          path: "/auth/organizationLocation",
          element: <LoadComponent component={OrganizationMap} requiredPrivileges={[APP_PRIVILEGES.Organization_Listing.key]} />
        },
        {
          path: "/auth/projectLocation",
          element: <LoadComponent component={ProjectMap} requiredPrivileges={[APP_PRIVILEGES.May_Access_Project_List.key]} />
        },
        {
          path: "/auth/addlead",
          element: <LoadComponent component={LeadDetail} />
        },
        {
          path: "/auth/processes/create",
          element: <LoadComponent component={CreateEditProcesses} />
        },
        {
          path: "/auth/processes/edit/:id",
          element: <LoadComponent component={CreateEditProcesses} />
        },
        {
          path: "/auth/processes/list",
          element: <LoadComponent component={Processes} />
        },
        {
          path: "/auth/processes/list/:id",
          element: <LoadComponent component={ProcessesDetail} />
        },
        {
          path: "/auth/item-categories/create",
          element: <LoadComponent component={CreateEditItemCategories} />
        },
        {
          path: "/auth/item-categories/edit/:id",
          element: <LoadComponent component={CreateEditItemCategories} />
        },
        {
          path: "/auth/item-categories/list",
          element: <LoadComponent component={ItemCategories} />
        },
        {
          path: "/auth/item-categories/list/:id",
          element: <LoadComponent component={ItemCategoriesDetail} />
        },
        {
          path: "/auth/item/create",
          element: <LoadComponent component={CreateEditItem} />
        },
        {
          path: "/auth/item/edit/:id",
          element: <LoadComponent component={CreateEditItem} />
        },
        {
          path: "/auth/item/list",
          element: <LoadComponent component={Item} />
        },
        {
          path: "/auth/item/list/:id",
          element: <LoadComponent component={ItemDetail} />
        },
        {
          path: "/auth/indent/create",
          element: <LoadComponent component={AddEditIndent} />
        },
        {
          path: "/auth/indent/edit/:id",
          element: <LoadComponent component={AddEditIndent} />
        },
        {
          path: "/auth/indent/list",
          element: <LoadComponent component={Indent} />
        },
        {
          path: "/auth/indent/list/:id",
          element: <LoadComponent component={IndentDetail} />
        },
        {
          path: "/auth/rfq/create",
          element: <LoadComponent component={AddEditRfq} />
        },
        {
          path: "/auth/rfq/edit/:id",
          element: <LoadComponent component={AddEditRfq} />
        },
        {
          path: "/auth/rfq/list",
          element: <LoadComponent component={Rfq} />
        },
        {
          path: "/auth/rfq/list/:id",
          element: <LoadComponent component={RfqDetail} />
        },
        {
          path: "/auth/quotation/create",
          element: <LoadComponent component={AddEditQuotation} />
        },
        {
          path: "/auth/quotation/edit/:id",
          element: <LoadComponent component={AddEditQuotation} />
        },
        {
          path: "/auth/quotation/list",
          element: <LoadComponent component={Quotation} />
        },
        {
          path: "/auth/quotation/list/:id",
          element: <LoadComponent component={QuotationDetail} />
        },
        {
          path: "/auth/po/create",
          element: <LoadComponent component={AddEditPO} />
        },
        {
          path: "/auth/po/edit/:id",
          element: <LoadComponent component={AddEditPO} />
        },
        {
          path: "/auth/po/list",
          element: <LoadComponent component={PO} />
        },
        {
          path: "/auth/gate-entry/create",
          element: <LoadComponent component={AddEditGateEntry} />
        },
        {
          path: "/auth/gate-entry/edit/:id",
          element: <LoadComponent component={AddEditGateEntry} />
        },
        {
          path: "/auth/gate-entry/list",
          element: <LoadComponent component={GateEntry} />
        },
        {
          path: "/auth/gate-entry/list/:id",
          element: <LoadComponent component={GateEntryDetail} />
        },
        {
          path: "/auth/po/list/:id",
          element: <LoadComponent component={PODetail} />
        },
        {
          path: "/auth/iot",
          element: <LoadComponent component={Iot} />
        },
        {
          path: "/auth/reports/projectReport",
          element: <LoadComponent component={ReportProject} />
        },
        {
          path: "/auth/iot-stats",
          element: <LoadComponent component={Iot} userType={UserType.VENDOR} />
        },
        {
          path: "/auth/jobdefinationdetails",
          element: <LoadComponent component={JobDefinationTabView} requiredPrivileges={[APP_PRIVILEGES.May_Update_Job.key]} />
        },
        {
          path: '/auth/trigger',
          element: <LoadComponent component={TriggerList} />
        },
        {
          path: '/auth/triggerdetails',
          element: <LoadComponent component={TriggerForm} />
        },
        {
          path: '/auth/machine/list',
          element: <LoadComponent component={MachineList} requiredPrivileges={[APP_PRIVILEGES.May_Access_Machine_List.key]} />
        },
        {
          path: '/auth/machine/add',
          element: <LoadComponent component={MachineDetail} requiredPrivileges={[APP_PRIVILEGES.May_Add_Machine.key]} />
        },
        {
          path: '/auth/machine/edit',
          element: <LoadComponent component={MachineDetail} requiredPrivileges={[APP_PRIVILEGES.May_Update_Machine.key]} />
        },
        {
          path: "/auth/product/sku-details",
          element: <LoadComponent component={SkuDetails} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Product.key, APP_PRIVILEGES.May_Update_Product.key]} />
        },

        {
          path: "/auth/contact/list",
          element: <LoadComponent component={ContactList} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session}  />
        },
        {
          path: "/auth/zoho-vendor/list",
          element: <LoadComponent component={ZohoVendorList} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session}  />
        },

        {
          path: "/auth/raw-material",
          element: <LoadComponent component={RawMaterialList} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Raw_Material_List.key]} />
        },
        {
          path: "/auth/raw-material/detail",
          element: <LoadComponent component={RawMaterialDetail} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Raw_Material.key]} />
        },
        {
          path: "/auth/warehouse",
          element: <LoadComponent component={WarehouseList} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Warehouse_List.key]} />
        },
        {
          path: "/auth/warehouse/detail",
          element: <LoadComponent component={WarehouseDetail} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Warehouse.key]} />
        },
        {
          path: "/auth/warehouse/map",
          element: <LoadComponent component={WarehouseMap} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Warehouse.key]} />
        },
        {
          path: "/auth/warehouse/map",
          element: <LoadComponent component={WarehouseMap} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Warehouse.key]} />
        },
        {
          path: "/auth/inventory/list",
          element: <LoadComponent component={InventoryList} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Warehouse_List.key]} />
        },
        {
          path: '/auth/inventory/detail/:id',
          element: <LoadComponent component={InventoryDetail} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Warehouse_List.key]} />
        },
        {
          path: '/auth/raw-material/chart',
          element: <LoadComponent component={RawMaterialChart} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Raw_Material.key]} />
        },
        {
          path: '/auth/supplier',
          element: <LoadComponent component={Supplier} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Access_Supplier_List.key]} />
        },
        {
          path: '/auth/supplierdetails',
          element: <LoadComponent component={SupplierDetails} userType={[UserType.SUPPLIER]} session={session} requiredPrivileges={[APP_PRIVILEGES.May_Add_Supplier.key, APP_PRIVILEGES.May_Update_Supplier.key]} />
        }
      ],
    },

  ]);
};

export { AllRoutes };
