import { POActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  po: [],
  singlePO: {},
  error: {},
  success: false,
};

const POReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case POActionTypes.CREATE_PO_REQUEST:
    case POActionTypes.EDIT_PO_REQUEST:
    case POActionTypes.FETCH_POS_REQUEST:
    case POActionTypes.FETCH_PO_BY_ID_REQUEST:
    case POActionTypes.RESET_PO_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case POActionTypes.SET_SINGLE_PO:
    return { ...state , singlePO: action.payload.data, loading:false }  
    case POActionTypes.CREATE_PO_SUCCESS:
      return {
        ...state,
        po: [...state.po, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case POActionTypes.EDIT_PO_SUCCESS:
      const updatedPOs = state.po.map((po: any) =>
        po.id === action.payload.data.id ? action.payload.data : po
      );
      return {
        ...state,
        po: updatedPOs,
        loading: false,
        error: {},
        success: true,
      };

    case POActionTypes.FETCH_POS_SUCCESS:
      return {
        ...state,
        po: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case POActionTypes.FETCH_PO_BY_ID_SUCCESS:
      return {
        ...state,
        singlePO: action.payload.data,
        po: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case POActionTypes.RESET_SINGLE_PO:
      return {
          ...state,
          singlePO: null,
      };

    case POActionTypes.CREATE_PO_FAILURE:
    case POActionTypes.EDIT_PO_FAILURE:
    case POActionTypes.FETCH_POS_FAILURE:
    case POActionTypes.FETCH_PO_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default POReducer;
