export enum RfqActionTypes {
    CREATE_RFQ_REQUEST = 'CREATE_RFQ_REQUEST',
    CREATE_RFQ_SUCCESS = 'CREATE_RFQ_SUCCESS',
    CREATE_RFQ_FAILURE = 'CREATE_RFQ_FAILURE',
    FETCH_RFQS_REQUEST = 'FETCH_RFQS_REQUEST',
    FETCH_RFQS_SUCCESS = 'FETCH_RFQS_SUCCESS',
    FETCH_RFQS_FAILURE = 'FETCH_RFQS_FAILURE',
    FETCH_RFQ_BY_ID_REQUEST = 'FETCH_RFQ_BY_ID_REQUEST',
    FETCH_RFQ_BY_ID_SUCCESS = 'FETCH_RFQ_BY_ID_SUCCESS',
    FETCH_RFQ_BY_ID_FAILURE = 'FETCH_RFQ_BY_ID_FAILURE',
    EDIT_RFQ_REQUEST = 'EDIT_RFQ_REQUEST',
    EDIT_RFQ_SUCCESS = 'EDIT_RFQ_SUCCESS',
    EDIT_RFQ_FAILURE = 'EDIT_RFQ_FAILURE',
    RESET_RFQ_SUCCESS_STATE = 'RESET_RFQ_SUCCESS_STATE',
    FETCH_SINGLE_RFQ = 'FETCH_SINGLE_RFQ',
    SET_SINGLE_RFQ = 'SET_SINGLE_RFQ',
    RESET_SINGLE_RFQ = 'RESET_SINGLE_RFQ'
}
