import { ActionTypes as ProcessActionTypes } from "./constant";

const INIT_STATE = {
    count: 0,
    loading: true,
    processes: [],
    error: {},
    success: false,
};

export type ProcessActionType = {
    type:
    ProcessActionTypes.CREATE_PROCESS_REQUEST
    | ProcessActionTypes.CREATE_PROCESS_SUCCESS
    | ProcessActionTypes.CREATE_PROCESS_FAILURE
    | ProcessActionTypes.EDIT_PROCESS_REQUEST
    | ProcessActionTypes.EDIT_PROCESS_SUCCESS
    | ProcessActionTypes.EDIT_PROCESS_FAILURE
    | ProcessActionTypes.FETCH_PROCESSES_REQUEST
    | ProcessActionTypes.FETCH_PROCESSES_SUCCESS
    | ProcessActionTypes.FETCH_PROCESSES_FAILURE
    | ProcessActionTypes.FETCH_PROCESS_BY_ID_REQUEST
    | ProcessActionTypes.FETCH_PROCESS_BY_ID_SUCCESS
    | ProcessActionTypes.FETCH_PROCESS_BY_ID_FAILURE
    | ProcessActionTypes.RESET_SUCCESS_STATE;

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    }
};

const ProcessReducer = (state = INIT_STATE, action: ProcessActionType) => {
    switch (action.type) {
        case ProcessActionTypes.CREATE_PROCESS_REQUEST:
        case ProcessActionTypes.EDIT_PROCESS_REQUEST:
        case ProcessActionTypes.FETCH_PROCESSES_REQUEST:
        case ProcessActionTypes.FETCH_PROCESS_BY_ID_REQUEST:
        case ProcessActionTypes.RESET_SUCCESS_STATE:
            return { ...state, loading: true, error: {}, success: false };

        case ProcessActionTypes.CREATE_PROCESS_SUCCESS:
            return { ...state, processes: [...state.processes, action.payload.data], loading: false, error: {}, success: true };

        case ProcessActionTypes.EDIT_PROCESS_SUCCESS:
            const updatedProcesses = state.processes.map((process: any) =>
                process.id === action.payload.data.id ? action.payload.data : process
            );
            return { ...state, processes: updatedProcesses, loading: false, error: {}, success: true };

        case ProcessActionTypes.FETCH_PROCESSES_SUCCESS:
            return { ...state, processes: action.payload.data, loading: false, error: {}, success: true, count: action.payload.data.count };

        case ProcessActionTypes.FETCH_PROCESS_BY_ID_SUCCESS:
            return { ...state, processes: [action.payload.data], loading: false, error: {}, success: true };

        case ProcessActionTypes.CREATE_PROCESS_FAILURE:
        case ProcessActionTypes.EDIT_PROCESS_FAILURE:
        case ProcessActionTypes.FETCH_PROCESSES_FAILURE:
        case ProcessActionTypes.FETCH_PROCESS_BY_ID_FAILURE:
            return { ...state, error: action.payload.error, loading: false, success: false };


        default:
            return state;
    }
};




export default ProcessReducer;
