import { ActionTypes } from './constants';

export type ZohoVendorActionType = {
    type: ActionTypes.SET_VENDOR | ActionTypes.SET_ERROR | ActionTypes.FETCH_VENDOR | ActionTypes.SYNC_VENDORS;

    payload: {} | string;
}

export const getVendor = (): ZohoVendorActionType => ({
    type: ActionTypes.FETCH_VENDOR,
    payload: {}
})

export const syncVendors = (): ZohoVendorActionType => ({ 
    type: ActionTypes.SYNC_VENDORS,
    payload: {}
});


export const zohoVendorApiResponse = (actionType: string, data: any): ZohoVendorActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_VENDOR:
            return {
                type: ActionTypes.SET_VENDOR,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}


export const zohoVendorApiError = (actionType: string, error: any): ZohoVendorActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})