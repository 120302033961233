import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    count: 0,
    loading: true,
    contacts: [],
    error: {},
    success: false,
}

type ContactActionType = {
    type:
    AuthActionTypes.SET_CONTACT
    | AuthActionTypes.FETCH_CONTACT
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.SYNC_CONTACTS
    | AuthActionTypes.SYNC_CONTACTS_SUCCESS; 

    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    }
}


const ContactReducer = (state: any = INIT_STATE, action: ContactActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_CONTACT:
            console.log("hello",action.payload)
            return { ...state, contacts: action.payload.data?.data?.response, loading: false, error: {}, success: true, count: action?.payload?.data?.data?.count};

        case AuthActionTypes.SET_ERROR:
            return { ...state, error: action.payload.error, loading: false };

        case AuthActionTypes.FETCH_CONTACT:
            return { ...state, loading: true };
        case AuthActionTypes.SYNC_CONTACTS: 
            return { ...state, loading: true };
        case AuthActionTypes.SYNC_CONTACTS_SUCCESS: 
            return { ...state, loading: false };
        default:
            return { ...state }
    }
}

export default ContactReducer;