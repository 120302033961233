import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ItemCategoryActionTypes } from './constant';
import {
  createItemCategory,
  fetchItemCategories,
  fetchItemCategoryById,
  editItemCategory,
} from 'helpers/api/itemCategories';
import {
  createItemCategorySuccess,
  createItemCategoryFailure,
  fetchItemCategoriesSuccess,
  fetchItemCategoriesFailure,
  fetchItemCategoryByIdSuccess,
  fetchItemCategoryByIdFailure,
  editItemCategorySuccess,
  editItemCategoryFailure,
} from './actions';

function* fetchItemCategoriesSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchItemCategories, page, limit, sortBy, sort, searchBy);
    const itemCategoriesData = response.data; 
    yield put(fetchItemCategoriesSuccess(itemCategoriesData));
  } catch (e: any) {
    yield put(fetchItemCategoriesFailure(e));
  }
}

function* createItemCategorySaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;

    const response = yield call(createItemCategory, data);
    const itemCategoryData = response.data;
    yield put(createItemCategorySuccess(itemCategoryData));
    yield put({ type: ItemCategoryActionTypes.FETCH_ITEM_CATEGORIES_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createItemCategoryFailure(e));
  }
}

function* fetchItemCategoryByIdSaga(action: any): SagaIterator {
  try {
    console.log(action.payload, "shubhi")
    console.log("Saga: Fetching item category by ID:", action.payload);  // Debug log
    const response = yield call(fetchItemCategoryById, action.payload);
    const itemCategoryData = response.data;
    console.log("Saga: API response for item category by ID:", itemCategoryData);  // Debug log

    yield put(fetchItemCategoryByIdSuccess(itemCategoryData));
  } catch (e: any) {
    yield put(fetchItemCategoryByIdFailure(e));
  }
}

function* editItemCategorySaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;

    const response = yield call(editItemCategory, id, data);
    const itemCategoryData = response.data;
    yield put(editItemCategorySuccess(itemCategoryData));
    yield put({ type: ItemCategoryActionTypes.FETCH_ITEM_CATEGORY_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editItemCategoryFailure(e));
  }
}

function* watchItemCategories() {
  yield takeEvery(ItemCategoryActionTypes.FETCH_ITEM_CATEGORIES_REQUEST, fetchItemCategoriesSaga);
  yield takeEvery(ItemCategoryActionTypes.CREATE_ITEM_CATEGORY_REQUEST, createItemCategorySaga);
  yield takeEvery(ItemCategoryActionTypes.FETCH_ITEM_CATEGORY_BY_ID_REQUEST, fetchItemCategoryByIdSaga);
  yield takeEvery(ItemCategoryActionTypes.EDIT_ITEM_CATEGORY_REQUEST, editItemCategorySaga);
}

export default watchItemCategories;
